import React from "react";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import LessonLayout from "~/layouts/lesson";

const Page = () => {
  return (
    <LessonLayout
      current_step={3}
      lesson={"Lesson 5"}
      color={"ms"}
      total_count={7}
      module_title="Putting Your Rubric to Work"
    >
      <ContentWrapper>
        <H2 underline>Step 3</H2>

        <p>Make sure you have a copy of your rubric to use for analyzing your chosen media item. Use your rubric to score the information in your chosen item. Make sure you can give reasons for assigning your scores for each category. </p>
        <p>Sometimes, testing out a rubric with new information can help you see things that can be improved. If you want to add to or revise your rubric, go ahead and make changes. If you do make changes on your rubric, you should use those revisions for all the media items that you are scoring. </p>
        <p>After you are done scoring, add up the total number of points on your rubric. </p>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

